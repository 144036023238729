"use strict";
/**
 * Activity (that are relevant for privacy) definitions
 *
 * ref. https://docs.google.com/document/d/1dRxFUFmhh2jGanzGZvfkK_6jtHPpHXWD7Qsi6KEugeE
 * & https://github.com/prebid/Prebid.js/issues/9546
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVITY_TRANSMIT_TID = exports.ACTIVITY_TRANSMIT_PRECISE_GEO = exports.ACTIVITY_TRANSMIT_UFPD = exports.ACTIVITY_TRANSMIT_EIDS = exports.ACTIVITY_REPORT_ANALYTICS = exports.ACTIVITY_FETCH_BIDS = exports.ACTIVITY_ENRICH_EIDS = exports.ACTIVITY_ENRICH_UFPD = exports.ACTIVITY_SYNC_USER = exports.ACTIVITY_ACCESS_DEVICE = void 0;
/**
 * accessDevice: some component wants to read or write to localStorage or cookies.
 */
exports.ACTIVITY_ACCESS_DEVICE = 'accessDevice';
/**
 * syncUser: A bid adapter wants to run a user sync.
 */
exports.ACTIVITY_SYNC_USER = 'syncUser';
/**
 * enrichUfpd: some component wants to add user first-party data to bid requests.
 */
exports.ACTIVITY_ENRICH_UFPD = 'enrichUfpd';
/**
 * enrichEids: some component wants to add user IDs to bid requests.
 */
exports.ACTIVITY_ENRICH_EIDS = 'enrichEids';
/**
 * fetchBid: a bidder wants to bid.
 */
exports.ACTIVITY_FETCH_BIDS = 'fetchBids';
/**
 * reportAnalytics: some component wants to phone home with analytics data.
 */
exports.ACTIVITY_REPORT_ANALYTICS = 'reportAnalytics';
/**
 * some component wants access to (and send along) user IDs
 */
exports.ACTIVITY_TRANSMIT_EIDS = 'transmitEids';
/**
 * transmitUfpd: some component wants access to (and send along) user FPD
 */
exports.ACTIVITY_TRANSMIT_UFPD = 'transmitUfpd';
/**
 * transmitPreciseGeo: some component wants access to (and send along) geolocation info
 */
exports.ACTIVITY_TRANSMIT_PRECISE_GEO = 'transmitPreciseGeo';
/**
 * transmit TID: some component wants access ot (and send along) transaction IDs
 */
exports.ACTIVITY_TRANSMIT_TID = 'transmitTid';
