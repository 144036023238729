"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.viewabilityTracker = exports.ViewabilityTracker = void 0;
var Page_1 = require("./Page");
var ViewabilityTracker = /** @class */ (function () {
    function ViewabilityTracker(monitorTabStatus, options) {
        var _this = this;
        if (monitorTabStatus === void 0) { monitorTabStatus = true; }
        if (options === void 0) { options = { root: null, threshold: 0.5 }; }
        this.callbacks = new Map();
        this.handleIntersection = function (entries) {
            entries.forEach(function (entry) {
                var callback = _this.callbacks.get(entry.target);
                if (callback) {
                    callback(entry);
                }
            });
        };
        // Monitor tab active state
        if (monitorTabStatus)
            this.monitorTabVisabilityState();
        // Create observer
        this.observer = new IntersectionObserver(this.handleIntersection, options);
    }
    ViewabilityTracker.prototype.trackElement = function (element, callback) {
        this.callbacks.set(element, callback);
        this.observer.observe(element);
    };
    ViewabilityTracker.prototype.untrackElement = function (element) {
        if (this.callbacks.get(element)) {
            this.callbacks.delete(element);
            this.observer.unobserve(element);
        }
    };
    ViewabilityTracker.prototype.monitorTabVisabilityState = function () {
        // Monitor tab active state
        document.addEventListener("visibilitychange", function () {
            Page_1.globalPage.handleTabVisabilityStateChange(document.visibilityState === "visible");
        });
    };
    return ViewabilityTracker;
}());
exports.ViewabilityTracker = ViewabilityTracker;
exports.viewabilityTracker = new ViewabilityTracker(true);
