"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_STORAGE_OPTIONS = exports.STORAGE_PREFIX = void 0;
// import { openDB, deleteDB, wrap, unwrap } from 'idb';
var ConsentHandler_1 = require("./consent/ConsentHandler");
var activities_1 = require("../modules/consent/activities");
var utils_1 = require("../utils/utils");
var moduleTypes_1 = require("../modules/consent/moduleTypes");
var STORAGE_TYPE_LOCALSTORAGE = 'html5';
var STORAGE_TYPE_COOKIES = 'cookie';
var DEFAULT_MAX_AGE = 60 * 60 * 24 * 30;
exports.STORAGE_PREFIX = '__bc_lh_';
exports.DEFAULT_STORAGE_OPTIONS = {
    'maxAge': DEFAULT_MAX_AGE,
    'expires': '',
    'path': '/',
    'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname),
    'sameSite': 'None',
    'secure': 1 // cookie will only be sent over SSL
};
var Storage = /** @class */ (function () {
    function Storage() {
    }
    Storage.isValid = function (cb, storageType, storageObj) {
        if (storageObj.componentType === moduleTypes_1.MODULE_TYPE_CORE) {
            return cb({ valid: true });
        }
        var params = __assign({ storageType: storageType }, storageObj);
        var result = {
            valid: ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ACCESS_DEVICE, params)
        };
        return cb(result);
    };
    /**
     *
     * @param key
     * @param value
     * @param storageObj
     * @param options
     * @returns
     */
    Storage.setItemInStorage = function (key, value, storageObj, options) {
        if (options === void 0) { options = exports.DEFAULT_STORAGE_OPTIONS; }
        // First try localStorage
        if (Storage.localStorageIsEnabled(storageObj)) {
            var maxAge = options.maxAge || DEFAULT_MAX_AGE;
            Storage.setDataInLocalStorage(key, value, storageObj, maxAge);
        }
        else if (Storage.cookiesAreEnabled(storageObj)) {
            Storage.setCookie(key, value, storageObj, options);
        }
        return true;
    };
    /**
     * @param {string} key
     * @param {string} [storageObj]
    */
    Storage.getItemFromStorage = function (key, storageObj) {
        var value = null;
        // First try localStorage
        if (Storage.localStorageIsEnabled(storageObj)) {
            value = Storage.getDataFromLocalStorage(key, storageObj);
        }
        if (!value && Storage.cookiesAreEnabled(storageObj)) {
            value = Storage.getCookie(key, storageObj);
        }
        return value;
    };
    Storage.setCookie = function (name, value, storageObj, options) {
        var cb = function (result) {
            if (result && result.valid) {
                value = encodeURIComponent(value);
                var cookieParts = ["".concat(name, "=").concat(value)];
                if (options) {
                    if (options.path) {
                        cookieParts.push("Path=".concat(options.path));
                    }
                    if (options.maxAge) {
                        cookieParts.push("Max-Age=".concat(options.maxAge));
                    }
                    else if (options.expires) {
                        var expires = new Date(options.expires).toUTCString();
                        cookieParts.push("Expires=".concat(expires));
                    }
                    if (options.domain) {
                        cookieParts.push("Domain=".concat(options.domain));
                    }
                    if (options.secure) {
                        cookieParts.push('Secure');
                    }
                    if (options.httpOnly) {
                        cookieParts.push('HttpOnly');
                    }
                    if (options.sameSite) {
                        cookieParts.push("SameSite=".concat(options.sameSite));
                    }
                }
                document.cookie = cookieParts.join('; ');
            }
        };
        return Storage.isValid(cb, STORAGE_TYPE_COOKIES, storageObj);
    };
    Storage.removeCookie = function (name, storageObj) {
        var cb = function (result) {
            if (result && result.valid) {
                Storage.setCookie(name, '', storageObj, { maxAge: -1 });
            }
        };
        return Storage.isValid(cb, STORAGE_TYPE_COOKIES, storageObj);
    };
    /**
     * @param {string} name
     * @returns {(string|null)}
     */
    Storage.getCookie = function (name, storageObj) {
        var cb = function (result) {
            if (result && result.valid) {
                var m = window.document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]*)\\s*(;|$)');
                return m ? decodeURIComponent(m[2]) : null;
            }
            return null;
        };
        return Storage.isValid(cb, STORAGE_TYPE_COOKIES, storageObj);
    };
    /**
     * @returns {boolean}
     */
    Storage.localStorageIsEnabled = function (storageObj) {
        var cb = function (result) {
            if (result && result.valid) {
                try {
                    localStorage.setItem("".concat(exports.STORAGE_PREFIX, ".cookieTest"), '1');
                    return localStorage.getItem("".concat(exports.STORAGE_PREFIX, ".cookieTest")) === '1';
                }
                catch (error) {
                    console.error('Local storage api disabled');
                }
                finally {
                    try {
                        localStorage.removeItem("".concat(exports.STORAGE_PREFIX, ".cookieTest"));
                    }
                    catch (error) {
                        console.error('Local storage api disabled');
                    }
                }
            }
            return false;
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    /**
     * @returns {boolean}
     */
    Storage.cookiesAreEnabled = function (storageObj) {
        var cb = function (result) {
            if (result && result.valid) {
                return Storage.checkCookieSupport();
            }
            return false;
        };
        return Storage.isValid(cb, STORAGE_TYPE_COOKIES, storageObj);
    };
    /**
     * @param {string} key
     * @param {string} value
     */
    Storage.setDataInLocalStorage = function (key, value, storageObj, maxAge) {
        var cb = function (result) {
            if (result && result.valid && Storage.hasLocalStorage()) {
                window.localStorage.setItem(key, value);
                // set expiration in Days
                maxAge = maxAge || DEFAULT_MAX_AGE;
                maxAge = new Date().getTime() + (maxAge * 1000);
                window.localStorage.setItem("".concat(key, "_exp"), maxAge);
            }
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    /**
     * @param {string} key
     * @returns {(string|null)}
     */
    Storage.getDataFromLocalStorage = function (key, storageObj) {
        var cb = function (result) {
            if (result && result.valid && Storage.hasLocalStorage()) {
                return window.localStorage.getItem(key);
            }
            return null;
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    // Garbage collection for local storage
    // If the expiration date has passed, remove the item from storage
    // @param {string} key
    // @returns {boolean}
    Storage.garbageCollectLocalStorage = function (key, storageObj) {
        var cb = function (result) {
            if (result && result.valid && Storage.hasLocalStorage()) {
                var expiration = window.localStorage.getItem("".concat(key, "_exp"));
                if (expiration && new Date().getTime() > parseInt(expiration)) {
                    Storage.removeDataFromLocalStorage(key, storageObj);
                    return true;
                }
            }
            return false;
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    /**
     * @param {string} key
     */
    Storage.removeDataFromLocalStorage = function (key, storageObj) {
        var cb = function (result) {
            if (result && result.valid && Storage.hasLocalStorage()) {
                window.localStorage.removeItem(key);
                window.localStorage.removeItem("".concat(key, "_exp"));
            }
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    /**
     * @returns {boolean}
     */
    Storage.hasLocalStorage = function () {
        try {
            return !!window.localStorage;
        }
        catch (e) {
            console.error('Local storage api disabled');
        }
        return false;
    };
    /**
     * @returns {(boolean|undefined)}
     */
    Storage.checkCookieSupport = function () {
        if (window.navigator.cookieEnabled || !!document.cookie.length) {
            return true;
        }
        return false;
    };
    /**
     *
     * @param storageObj
     * @returns {boolean}
     */
    Storage.hasIndexedDB = function (storageObj) {
        var cb = function (result) {
            if (result && result.valid) {
                try {
                    return !!window.indexedDB;
                }
                catch (e) {
                    console.error('IndexedDB api disabled');
                }
            }
            return false;
        };
        return Storage.isValid(cb, STORAGE_TYPE_LOCALSTORAGE, storageObj);
    };
    return Storage;
}());
exports.default = Storage;
