"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalUser = exports.User = void 0;
var utils_1 = require("../utils/utils");
var Storage_1 = __importStar(require("./Storage"));
var __1 = require("../");
var User = /** @class */ (function () {
    function User() {
        this._uuid_storage_name = "".concat(Storage_1.STORAGE_PREFIX, "uuid");
        this.new_user = false;
        this.acct_type = 'guest';
        this._storageObj = { 'componentType': 'core' };
        this.getUUID();
        this.saveUUID();
    }
    User.prototype.getUUID = function () {
        // if uuid is already set, return it
        if (this._uuid)
            return this._uuid;
        // get uuid from local storage
        this._uuid = Storage_1.default.getItemFromStorage(this._uuid_storage_name, this._storageObj);
        // if uuid is not set, generate new one
        if (!this._uuid) {
            this._uuid = (0, utils_1.generateUUID)();
            // set new user flag
            this.new_user = true;
        }
        return this._uuid;
    };
    User.prototype.saveUUID = function () {
        var _a;
        // if uuid is not set, return false
        if (!this._uuid)
            return false;
        var maxAge = 60 * 60 * 24 * 365 * 1; // 1 years
        // set storage options
        var storageOptions = {
            'maxAge': maxAge,
            'expires': '',
            'path': '/',
            'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname),
            'sameSite': 'None',
            'secure': 1 // cookie will only be sent over SSL
        };
        // save id to local storage
        Storage_1.default.setItemInStorage(this._uuid_storage_name, this._uuid, this._storageObj, storageOptions);
        return true;
    };
    User.prototype.setAcctType = function () {
        var _a;
        try {
            // get account type from global tag
            var acct_type = (_a = __1.BCLighthouseTag === null || __1.BCLighthouseTag === void 0 ? void 0 : __1.BCLighthouseTag.metadata) === null || _a === void 0 ? void 0 : _a.acct_type;
            // possible account types
            var acct_types = ['guest', 'logged_in', 'paid'];
            // if account type is valid, set it
            if (acct_type && acct_types.includes(acct_type)) {
                this.acct_type = acct_type;
            }
        }
        catch (e) {
            console.error('BigCrunch: Error setting account type:', e);
        }
    };
    return User;
}());
exports.User = User;
exports.globalUser = new User();
