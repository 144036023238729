"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setConsentConfig = exports.loadIfMissing = void 0;
/**
 * This module adds USPAPI (CCPA) consentManagement support. It
 * interacts with supported USP Consent APIs to grab the user's consent
 * information and make it available for any USP (CCPA) supported adapters to
 * read/pass this information to their system.
 */
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var cmpClient_1 = require("./cmpClient");
var ConsentHandler_1 = require("../../classes/consent/ConsentHandler");
var DEFAULT_CONSENT_TIMEOUT = 50;
var USPAPI_VERSION = 1;
var consentTimeout = DEFAULT_CONSENT_TIMEOUT;
var consentData;
/**
 * Lookup consent data and store it in the `consentData` global as well as `adapterManager.js`' uspDataHanlder.
 *
 * @param cb a callback that takes an error message and extra error arguments; all args will be undefined if consent
 * data was retrieved successfully.
 */
function loadConsentData(cb) {
    var timer = null;
    var isDone = false;
    function done(consentData, errMsg) {
        var extraArgs = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            extraArgs[_i - 2] = arguments[_i];
        }
        if (timer != null) {
            clearTimeout(timer);
        }
        isDone = true;
        ConsentHandler_1.uspConsentHandler.setConsentData(consentData);
        if (cb != null) {
            cb.apply(void 0, __spreadArray([false, errMsg], extraArgs, false));
        }
    }
    var callbacks = {
        onSuccess: done,
        onError: function (errMsg) {
            var extraArgs = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                extraArgs[_i - 1] = arguments[_i];
            }
            done.apply(void 0, __spreadArray([null, "".concat(errMsg, " Resuming auction without consent data as per consentManagement config.")], extraArgs, false));
        }
    };
    lookupUspConsent(callbacks);
    if (!isDone) {
        if (consentTimeout === 0) {
            processUspData(undefined, callbacks);
        }
        else {
            timer = setTimeout(callbacks.onError.bind(null, 'USPAPI workflow exceeded timeout threshold.'), consentTimeout);
        }
    }
    // add eventListener for event that country code was recieved
    document.addEventListener('BcConsentCountryCode', function (event) {
        if (event.detail !== 'US' && !isDone) {
            done({
                usPrivacy: '1---'
            }, '');
        }
    });
}
/**
 * This function handles interacting with an USP compliant consent manager to obtain the consent information of the user.
 * Given the async nature of the USP's API, we pass in acting success/error callback functions to exit this function
 * based on the appropriate result.
 */
function lookupUspConsent(_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError;
    function handleUspApiResponseCallbacks() {
        var uspResponse = {
            usPrivacy: ''
        };
        function afterEach() {
            if (uspResponse.usPrivacy) {
                processUspData(uspResponse, { onSuccess: onSuccess, onError: onError });
            }
            else {
                onError('Unable to get USP consent string.');
            }
        }
        return {
            consentDataCallback: function (consentResponse, success) {
                if (success && consentResponse.uspString) {
                    uspResponse.usPrivacy = consentResponse.uspString;
                }
                afterEach();
            },
        };
    }
    var callbackHandler = handleUspApiResponseCallbacks();
    var cmp = (0, cmpClient_1.cmpClient)({
        apiName: '__uspapi',
        apiVersion: USPAPI_VERSION,
        apiArgs: ['command', 'version', 'callback'],
    });
    if (!cmp) {
        return onError('USP CMP not found.');
    }
    else {
        ConsentHandler_1.uspConsentHandler.setCmpFound();
    }
    if (cmp.isDirect) {
        (0, log_1.logInfo)('Detected USP CMP is directly accessible, calling it now...');
    }
    // else {
    //     logInfo(
    //         'Detected USP CMP is outside the current iframe where Prebid.js is located, calling it now...'
    //     );
    // }
    cmp({
        command: 'getUSPData',
        callback: callbackHandler.consentDataCallback
    });
    // cmp({
    //     command: 'registerDeletion',
    //     callback: adapterManager.callDataDeletionRequest
    // }).catch(e => {
    //     logError('Error invoking CMP `registerDeletion`:', e);
    // });
}
/**
 * Like `loadConsentData`, but cache and re-use previously loaded data.
 * @param cb
 */
function loadIfMissing(cb) {
    if (consentData && consentData.usPrivacy) {
        (0, log_1.logInfo)('User consent information already known.  Pulling internally stored information...');
        cb(false);
    }
    else {
        loadConsentData(cb);
    }
}
exports.loadIfMissing = loadIfMissing;
/**
 * This function checks the consent data provided by USPAPI to ensure it's in an expected state.
 * If it's bad, we exit the module depending on config settings.
 * If it's good, then we store the value and exits the module.
 * @param {object} consentObject required; object returned by USPAPI that contains user's consent choices
 * @param {function(string)} onSuccess callback accepting the resolved consent USP consent string
 * @param {function(string, ...{}?)} onError callback accepting error message and any extra error arguments (used purely for logging)
 */
function processUspData(consentObject, _a) {
    if (consentObject === void 0) { consentObject = { usPrivacy: null }; }
    var onSuccess = _a.onSuccess, onError = _a.onError;
    var valid = !!(consentObject && consentObject.usPrivacy);
    if (!valid) {
        onError("USPAPI returned unexpected value during lookup process.", consentObject);
        return;
    }
    storeUspConsentData(consentObject);
    onSuccess(consentData);
}
/**
 * Stores USP data locally in module and then invokes uspDataHandler.setConsentData() to make information available in adaptermanger.js for later in the auction
 * @param {object} consentObject required; an object representing user's consent choices (can be undefined in certain use-cases for this function only)
 */
function storeUspConsentData(consentObject) {
    if (consentObject && consentObject.usPrivacy) {
        consentData = consentObject;
    }
}
/**
 * A configuration function that initializes some module variables, as well as add a hook into the requestBids function
 * @param {object} config required; consentManagementUSP module config settings; usp (string), timeout (int), allowAuctionWithoutConsent (boolean)
 */
function setConsentConfig(config) {
    config = config && config.usp;
    if (!config || typeof config !== 'object') {
        (0, log_1.logWarn)('consentManagement.usp config not defined, using defaults');
    }
    if (config && (0, utils_1.isNumber)(config.timeout)) {
        consentTimeout = config.timeout;
    }
    else {
        consentTimeout = DEFAULT_CONSENT_TIMEOUT;
        (0, log_1.logInfo)("consentManagement.usp config did not specify timeout. Using system default setting (".concat(DEFAULT_CONSENT_TIMEOUT, ")."));
    }
    (0, log_1.logInfo)('consentManagement.uspc module has been activated...');
    enableConsentManagement();
}
exports.setConsentConfig = setConsentConfig;
function enableConsentManagement() {
    loadConsentData(function () { }); // immediately look up consent data to make it available without requiring an auction
}
